import { template as template_ddf3271726014b01a3127cc3dc677b2a } from "@ember/template-compiler";
const EmptyState = template_ddf3271726014b01a3127cc3dc677b2a(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
